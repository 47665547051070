<template>
    <div class="mb-20 overflow-hidden rounded-lg bg-white">
        <ResponsiveImage
            v-if="isMediumOrGreaterScreen && model.image"
            :image-url="model.image.url"
            :aspect-ratio="6 / 1"/>
        <ResponsiveImage
            v-else-if="model.mobileImage"
            :image-url="model.mobileImage.url"
            :aspect-ratio="4 / 3"/>
    </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { CategoryBannerBlockModel } from '@/api/cms';

defineProps<{
    model: CategoryBannerBlockModel;
}>();

const breakpoints = useBreakpoints();
const isMediumOrGreaterScreen = breakpoints.greater('md');
</script>
