<template>
    <div>
        <div class="block-spacing mx-10 grid grid-flow-row auto-rows-max grid-cols-8 gap-10 md:gap-20">
            <div class="order-2 col-span-4 md:order-1 md:col-span-2">
                <RenderBlocks :model="model.column1" :column-index="isMobile ? 1 : 0" :total-columns="3" :compact="true"/>
            </div>
            <div class="order-1 col-span-8 md:order-2 md:col-span-4">
                <RenderBlocks :model="model.column2" :column-index="isMobile ? 0 : 1" :total-columns="3" :compact="false"/>
            </div>
            <div class="order-3 col-span-4 md:order-3 md:col-span-2">
                <RenderBlocks :model="model.column3" :column-index="2" :total-columns="3" :compact="true"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ThreeColumnGridContentBlockModel } from '@/api/cms';
import RenderBlocks from '@/project/pages/content-page/RenderBlocks.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
defineProps<{
    model: ThreeColumnGridContentBlockModel;
}>();

const breakpoint = useBreakpoints();
const isMobile = breakpoint.smaller('md');
</script>

<style scoped>
.blockSwiper {
    @apply pb-30;
}

.blockSwiper .swiper-wrapper {
    height: auto;
}

</style>
