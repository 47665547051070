<template>
    <div class="px-10 sm:px-0 block-spacing">
        <div class="grid grid-cols-8 grid-flow-row auto-rows-max gap-10 md:grid-cols-20 lg:grid-cols-25 xl:grid-cols-21"
             :class="[{'productband': hideProducts, 'active': (hideProducts && showAllProducts)}, getProductCount]">
            <ProductTile v-for="(item, index) in model" :tracking-context="{index, impressionList: {listType: 'Product band', choosingMechanism: 'Editor'}}" :key="item.sku" :model="item" class="flex-col col-span-4 md:col-span-4 lg:col-span-5 xl:col-span-3 product-item"/>
        </div>
        <div v-if="link && link.name != ''" class="flex justify-center items-center mt-20">
            <RouterLinkConditional :url="link.url" :target="link.target">
                <Button :label="link.name" :pill="true" :primary="false">
                    <template #right>
                        <CIcon name="arrow-right" width="12" height="12"/>
                    </template>
                </Button>
            </RouterLinkConditional>
        </div>
        <div v-else-if="hideProducts" class="flex justify-center items-center mt-20 showhide-toggle">
            <span class="inline-block mr-20 w-full h-0 border-products"></span>
            <Button :label="showAllProducts ? $translate('Client.Common.HideAllProducts') : $translate('Client.Common.ShowAllProducts')"
                    :pill="true"
                    :primary="false"
                    @click="showAllProducts = !showAllProducts">
                <template #right>
                    <CIcon v-if="showAllProducts" name="arrow-up" width="12" height="12"/>
                    <CIcon v-else name="arrow-down" width="12" height="12"/>
                </template>
            </Button>
            <span class="inline-block ml-20 w-full h-0 border-products"></span>
        </div>
    </div>
</template>

<script lang="ts" setup>

import { ProductTileViewModel } from '@/api/commerce/models';
import ProductTile from '@/project/products/product-tile/ProductTile.vue';
import Button from '@/project/components/button/Button.vue';
import { Link } from '@/api/cms/models/link';
import { computed, ref } from 'vue';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';

const props = defineProps<{
    model: ProductTileViewModel[],
    link?: Link,
    hideProducts?: boolean;
}>();

const showAllProducts = ref(false);

const getProductCount = computed(() => {
    const count = props.model.length;
    const style:string[] = [];
    if (count > 4) {
        style.push('count-md');
    }
    if (count > 6) {
        style.push('count-lg');
    }

    return style.join(' ');
});
</script>

<style scoped>

.border-products{
    border-top: 1px solid #e3e3e3;
}
.productband + .showhide-toggle { /* if hide products is enabled, start with hiding products and display according to amount of products and screen size */
    display: none;
}

@screen productbandmd{ /* display show/hide toggle button and hide products when count is above 4    */
    .productband:not(.active) > .product-item:nth-child(1n+5){
        display: none;
    }
    .count-md + .showhide-toggle{
        display: flex;
    }
}

@screen productbandlg{/* display show/hide toggle button and hide products when count is above 5    */
    .productband:not(.active)  > .product-item:nth-child(1n+6){
        display: none;
    }
     .count-md + .showhide-toggle{
        display: flex;
    }
}
@screen xl{/* display show/hide toggle button and hide products when count is above 7    */
    .productband:not(.active)  > .product-item:nth-child(1n+8){
        display: none;
    }
    .count-lg + .showhide-toggle{
        display: flex;
    }

}

</style>
