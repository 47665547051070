<template>
    <div
        class="block-spacing banner-action relative flex flex-col overflow-hidden rounded-lg bg-white p-40"
        :class="!totalColumns ? 'h-300 lg:h-500 mx-10 md:mx-0' : 'aspect-[10/7]'">
        <component
            :is="model.link.linkType !== LinkType.External ? 'router-link' : 'a'"
            v-if="model.link && model.link?.url"
            :to="model.link?.url"
            :href="model.link.linkType !== LinkType.External ? undefined : model.link?.url"
            :target="model.link.target"
            class="absolute inset-0 z-[3] opacity-0"
            @click="onClick">
            {{ model.link.name }}
        </component>

        <div class="z-2 absolute inset-0 flex flex-col bg-transparent transition" :class="[position(), `${compact ? 'p-10 sm:p-20 md:p-10 lg:p-15 xl:p-20' : 'p-20 sm:p-40 md:p-20 lg:p-40'}`]">
            <div>
                <h4 class="font-bold leading-[3rem]" :class="[color(model.labelColor), `${compact ? 'text-18 sm:text-24 md:text-18 lg:text-24 xl:text-30' : 'text-22 sm:text-40 md:text-30 lg:text-34 lg:leading-36'}`]">
                    {{ model.headline }}
                </h4>
                <p class="md:mt-10" :class="[color(model.textColor),{'font-bold': model.textWeight === 'Bold'}, `${compact ? 'text-14 sm:text-16 sm:mt-10 md:text-14 md:mt-0 lg:mt-4 xl:text-18 xl:mt-10' : 'text-14 sm:text-20 md:text-14 lg:text-20'}`]">
                    {{ model.text }}
                </p>
                <Button
                    v-if="model.link && model.link?.url"
                    :label="model.link.name"
                    :primary="model.linkButtonType === 'Primary' ? true : (model.linkButtonType === 'Secondary' ? false : undefined)"
                    :transparent="model.linkButtonType === 'Text' ? true : false"
                    :class="{'text-blue text-button' : model.linkButtonType === 'Text'}"
                    :size="'medium'"
                    class="mt-10 px-40">
                    <template v-if="model.linkButtonType !== 'Text'" #right>
                        <CIcon name="arrow-right" width="12" height="12"/>
                    </template>
                </Button><!-- linkButtonType needs to return undefined if matching Text, otherwise button won't be transparent -->
            </div>
        </div>
        <div class="z-1 bg-hover absolute inset-0 bg-gradient-to-br from-[rgba(0,0,0,.2)] to-[rgba(0,0,0,.1)] opacity-0 transition duration-[.8s]"></div>
        <div class="absolute inset-0 z-0">
            <ResponsiveImage
                v-if="model.image"
                class="!h-full"
                :aspect-ratio="!totalColumns ? 16 / 9 : 10 / 7"
                :width-on-screen="{
                    xs: 100,
                    md: imageWidthMedium(totalColumns)
                }"
                :image-url="model.image.url"
                :anchor="`${model.image.focalPoint.left},${model.image.focalPoint.top}`"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import Button from '@/project/components/button/Button.vue';
import { BannerBlockModel, LinkType } from '@/api/cms';
import { trackPromotionImpressions, trackPromotionClick } from '@/project/tracking/tracking.service';

const props = defineProps<{
    model: BannerBlockModel
    columnIndex?: number
    totalColumns?: number
    indexInColumn: number
    compact?: boolean
}>();

const position = () => {
    switch (props.model.textPosition) {
    case 'Top':
        return 'justify-start';
    case 'Middle':
        return 'justify-center';
    case 'Bottom':
        return 'justify-end';
    }
};

const color = (color:string) => {
    switch (color) {
    case 'Black':
        return 'text-black';
    case 'White':
        return 'text-white';
    case 'Blue':
        return 'text-blue';
    }
};

const imageWidthMedium = (totalColumns: number | undefined) => {
    switch (totalColumns) {
    case 1:
        return 100;
    case 2:
        return 50;
    case 3:
        return 33;
    default:
        return 100;
    }
};

let index = 0;
if (props.columnIndex !== undefined && props.totalColumns) {
    index = props.indexInColumn * props.totalColumns + props.columnIndex;
}

trackPromotionImpressions([{
    nameOfModule: 'BannerBlock',
    headlineOrImageName: props.model.headline ? props.model.headline : props.model.image?.name ?? '',
    creative: props.model.link.name,
    index,
}]);

function onClick() {
    trackPromotionClick([{
        nameOfModule: 'BannerBlock',
        headlineOrImageName: props.model.headline ? props.model.headline : props.model.image?.name ?? '',
        creative: props.model.link.name,
        index,
    }]);
}

</script>

<style scoped>
    .text-button, .text-button:deep() span{
        padding-left: 0;
        padding-right: 0;
    }
    .banner-action:hover > a + div + .bg-hover{ /* only apply hover effecty if link is added */
        @apply opacity-100;
    }
</style>
