<template>
    <component
        :is="block"
        v-for="(block, index) in blocks"
        :key="'block' + index"
        :model="model[index].content"
        :column-index="columnIndex"
        :total-columns="totalColumns"
        :index-in-column="index"
        :compact="compact"
        :alias="model[index].alias"/>
</template>

<script lang="ts" setup>
import { JsonContent } from '@/api/cms';
import { blockResolver } from '@/core/content/componentResolver';

const props = defineProps<{
    model: Array<JsonContent>
    columnIndex?: number
    totalColumns?: number
    compact?: boolean
}>();

const blocks = props.model.map(x => blockResolver.resolve(x.alias));

</script>
