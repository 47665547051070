<template>
    <div class=" bg-white py-30" :class="{'pr-20 pl-30 pt-20': !minimal}">
        <div class="grid grid-cols-4 lg:grid-cols-12 lg:space-x-20">
            <div class="col-span-4 md:col-span-2 lg:col-span-4">
                <div class="mb-40">
                    <h4 class="mb-10 font-bold text-18">
                        {{ $translate('Client.CheckoutPage.CustomerInformation.InvoiceAddress') }}
                    </h4>
                    <h5>
                        {{ model.invoiceAddress?.firstName }} {{ model.invoiceAddress?.lastName }}
                    </h5>
                    <p class="mb-15">
                        <span class="block">{{ model.invoiceAddress?.addressLine1 }}</span>
                        <span class="block">{{ model.invoiceAddress?.addressLine2 }}</span>
                        <span class="block">{{ model.invoiceAddress?.zipCode }} {{ model.invoiceAddress?.city }} </span>
                    </p>
                    <p v-if="model.invoiceAddress?.companyName && model.invoiceAddress?.companySocialSecurityNumber" class="mb-15">
                        <span class="block">{{ model.invoiceAddress?.companyName }}</span>
                        <span class="block">{{ model.invoiceAddress?.companySocialSecurityNumber }}</span>
                    </p>
                    <p>
                        <span class="block">{{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}: {{ model.invoiceAddress?.mobile }}</span>
                        <span class="block">{{ $translate('Client.CheckoutPage.CustomerInformation.Email') }}: {{ model.invoiceAddress?.email }}</span>
                    </p>
                </div>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-4">
                <div v-if="model.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery" class="mb-40">
                    <h4 class="font-bold text-18">
                        {{ $translate('Client.CheckoutPage.Confirmation.DeliveryAddress') }}
                    </h4>
                    <span v-if="deliverySameAsInvoice">{{ $translate('Client.CheckoutPage.Confirmation.SameAsInvoice') }}</span>
                    <div v-else-if="model.deliveryAddress">
                        <p>
                            {{ model.deliveryAddress?.firstName }} {{ model.deliveryAddress?.lastName }}
                        </p>
                        <p class="mb-15">
                            <span class="block">{{ model.deliveryAddress.addressLine1 }}</span>
                            <span class="block">{{ model.deliveryAddress.addressLine2 }}</span>
                            <span class="block">{{ model.deliveryAddress.zipCode }} {{ model.deliveryAddress.city }} </span>
                        </p>
                        <p>
                            <span class="block">{{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}: {{ model.deliveryAddress.mobile }}</span>
                        </p>
                    </div>
                </div>
                <div v-else class="mb-40">
                    <h4 class="font-bold text-18">
                        {{ $translate('Client.CheckoutPage.Confirmation.PickUpPoint') }}
                    </h4>
                    <h5 class="text-15">
                        {{ model.store?.name }}
                    </h5>
                    <div>
                        <p class="mb-15">
                            <span class="block">{{ model.store?.address.street }} {{ model.store?.address.houseNumber }}</span>
                            <span class="block">{{ model.store?.address?.zipCode }} {{ model.store?.address.city }} </span>
                        </p>
                    </div>
                </div>
                <div class="mb-40">
                    <h4 v-if="model.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery" class="font-bold text-18">
                        {{ $translate('Client.CheckoutPage.Confirmation.Delivery') }}
                    </h4>
                    <h4 v-else class="font-bold text-18">
                        {{ $translate('Client.CheckoutPage.Confirmation.PickUpTime') }}
                    </h4>
                    <span class="block">{{ deliveryText }}, {{ deliveryDay }}</span>
                    <span>{{ deliveryTime }}</span>
                </div>
                <div v-if="model.noteToDriver" class="mb-40">
                    <h4 class="font-bold text-18">
                        {{ $translate('Client.CheckoutPage.Confirmation.Comment') }}
                    </h4>
                    <span>{{ model.noteToDriver }}</span>
                </div>
            </div>
            <div v-if="!minimal" class="col-span-4 md:col-span-4 lg:col-span-4">
                <div class="rounded-lg bg-background">
                    <div class="px-20 border-b-2 border-white py-15">
                        <span class="font-bold text-18">{{ $translate('Client.CheckoutPage.Confirmation.Payment') }}</span>
                    </div>
                    <div class="px-20 border-b-2 border-white py-15">
                        <div class="flex">
                            <span>{{ $translate('Client.CheckoutPage.Confirmation.PaidWithBonus') }}</span><span class="ml-auto">{{ formatPrice(model.paidBonus) }}</span>
                        </div>
                        <div class="flex">
                            <span>{{ $translate('Client.CheckoutPage.Confirmation.PaidWithCard') }}</span><span class="ml-auto">{{ formatPrice(model.paidAmount) }}</span>
                        </div>
                    </div>
                    <div class="flex px-20 border-b-2 border-white py-15">
                        <span class="font-bold text-15">{{ $translate('Client.BasketPage.Total') }}</span><span class="ml-auto font-bold text-15">{{ formatPrice(model.total) }}</span>
                    </div>
                    <div v-if="model.earnedBonus" class="flex items-center px-20 border-b-2 border-white bg-backgroundContrast py-15">
                        <span class="flex items-center font-bold">
                            <CIcon name="checkcircle" width="20" height="20" class="mr-10"/>
                            <span class="text-bonus">{{ $translate('Client.CheckoutPage.Confirmation.EarnedBonus') }}</span>
                        </span>
                        <span class="ml-auto font-bold text-bonus">
                            {{ formatPrice(model.earnedBonus) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { OrderDetailsViewModel, TimeSlotTypeViewModel } from '@/api/commerce';
import { formatPrice } from '@/project/products/priceFormatter';
import dictionary from '@/core/dictionary/dictionary';
import { getWeekday, getDate, getTime } from '@/project/utils/datetime';
import { computed, ref } from 'vue';

const props = defineProps<{
    minimal?:boolean, /* is true, it will not display payment block */
    model: OrderDetailsViewModel
}>();

const deliveryText = props.model.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery ? dictionary.get('Client.CheckoutPage.Delivery.HomeDeliveryOption') : dictionary.get('Client.CheckoutPage.Delivery.PickUpInStoreOption');
const deliveryTime = ref('');
const deliveryDay = ref('');

const deliverySameAsInvoice = computed(() => {
    const d = props.model.deliveryAddress;
    const i = props.model.invoiceAddress;

    return d?.addressLine1 === i?.addressLine1 && d?.addressLine2 === i?.addressLine2 && d?.zipCode && i?.zipCode;
});

if (props.model.timeSlot) {
    const d = props.model.timeSlot.from;
    const fDate = getDate(d);

    const day = getWeekday(d);

    const fromTime = getTime(d);
    const toTime = getTime(props.model.timeSlot.to);

    deliveryDay.value = day;
    deliveryTime.value = `${fDate} ${dictionary.get('Client.CheckoutPage.Confirmation.Between')} ${fromTime}-${toTime}`;
}

</script>
